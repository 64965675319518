import React, { useState, useEffect } from 'react';
import Header from '../../Components/Layout/Header/Header';
import './Menu.css';
import Banner from '../../Components/Layout/Banner/Banner';
import Footer from '../../Components/Layout/Footer/Footer';
import { ReactComponent as PhotoMenuSquare } from '../../assets/img/Pictures/web/menu/menu-square.svg';
import { ReactComponent as PhotoMenuRectangular } from '../../assets/img/Pictures/web/menu/menu-rectangularx.svg';
import { Helmet } from 'react-helmet-async';

export default function Menu() {

  // changements si écran < 770px
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 770);

  const handleResize = () => {
    setIsWideScreen(window.innerWidth <= 770);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name='description' content='Explorez notre menu varié de Baos croustillants, des options savoureuses de poulet, bœuf, thon et végétariennes. Savourez chaque bouchée chez Crousti Truck.' />
      </Helmet>
      <Header />
      <main className="menu background">
        <Banner />
        {/* <Construction /> */}

        {isWideScreen ? < PhotoMenuRectangular className='menu__flyer' alt='menu du foodtruck' />
          : < PhotoMenuSquare className='menu__flyer' alt='menu du foodtruck' />}

      </main>

      <Footer />
    </>
  )
}
