export default function useScrollToTop() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };

    return scrollToTop;
}
