import React from 'react';
import Header from '../../Components/Layout/Header/Header';
import './Contact.css';
import { Link } from "react-router-dom";
import Footer from '../../Components/Layout/Footer/Footer';
import { Helmet } from 'react-helmet-async';

export default function Contact() {

    return (
        <>
            <Helmet>
                <meta name='description' content="Besoin d'informations ? Contactez Crousti Truck pour toutes vos questions. Nous sommes là pour vous offrir une expérience culinaire exceptionnelle." />
            </Helmet>
            <Header />
            <main className="contact background">
                <article className="contact-content">
                    <div className="backgroundCard backgroundCardContact-txt1">
                        <p className="contact__p">Vous avez des questions, des commentaires ou vous souhaitez réserver Crousti Truck pour votre prochain événement ?<br className='contact-br' /> Nous sommes là pour vous aider et nous serions ravis de vous accueillir.</p>
                        <p className="contact__p contact__p2">N'hésitez pas à nous contacter par téléphone ou par email, ou utilisez le formulaire ci-dessous pour nous envoyer un message directement.<br className='contact-br' />  Nous sommes impatients de vous servir et de partager notre passion pour la cuisine de rue avec vous.</p>
                    </div>

                    <div className="contact__form">
                        <Link className='btn btn-big btn-big--home btn-form' target="_blank" to="https://forms.gle/FAa8PEtj44Lzahm2A"><i className="contact__form__icon-envelope fa-regular fa-envelope" alt="email"></i>Me contacter</Link>
                        <ul className="contact__form__coordinates backgroundCard-small-grey">
                            <li className="contact__form__coordinates__p">Crousti Truck</li>
                            <li className="contact__form__coordinates__p">Mail:<a className='gradient-text' href="mailto:contact@crousti-truck.fr"> contact@crousti-truck.fr</a></li>
                            <li className="contact__form__coordinates__p">Tél: <a className='gradient-text' href="tel:+33652150605">06 52 15 06 05</a></li>
                        </ul>
                    </div>

                    <div className="contact__social backgroundCard-small" >
                        <p className="contact__social__description backgroundCardContact-txt2">Suivez-nous sur les réseaux sociaux pour rester à jour sur nos emplacements actuels, nos créations spéciales et nos événements à venir.<br className='contact-br' /> Rejoignez notre communauté gourmande en cliquant sur ces liens:
                            <span className="contact__social__btn">
                                <Link className='btn home-contact__social-btn home-contact__social-btn--facebook' target="_blank" to="https://www.facebook.com/people/Crousti-Truck/61551633789092" aria-label="Suivez-nous sur Facebook"><i className="fa-brands fa-facebook home-contact__social-btn__svg" alt="facebook"></i></Link>
                                <Link className='btn home-contact__social-btn' target="_blank" to="https://www.instagram.com/crousti_truck" aria-label="Suivez-nous sur Instagram"><i className="fa-brands fa-instagram home-contact__social-btn__svg" alt="instagram"></i></Link>
                            </span>
                        </p>

                    </div>
                </article>
            </main>
            <Footer />
        </>
    )
}
