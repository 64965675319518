import React from 'react';
import Header from '../../Components/Layout/Header/Header';
import './Caterer.css';
import Footer from '../../Components/Layout/Footer/Footer';
import { Link } from 'react-router-dom';
import { ReactComponent as PhotoFramesOval1 } from '../../assets/img/Pictures/web_avec_cadres_svg/traiteur/1.svg';
import { ReactComponent as PhotoFramesOval2 } from '../../assets/img/Pictures/web_avec_cadres_svg/traiteur/2.svg';
import { ReactComponent as PhotoFramesOval3 } from '../../assets/img/Pictures/web_avec_cadres_svg/traiteur/3.svg';
import { ReactComponent as PhotoFramesOval6 } from '../../assets/img/Pictures/web_avec_cadres_svg/traiteur/6.svg';
import { ReactComponent as PhotoFramesOval8 } from '../../assets/img/Pictures/web_avec_cadres_svg/traiteur/8.svg';
import { ReactComponent as PhotoFramesOval9 } from '../../assets/img/Pictures/web_avec_cadres_svg/traiteur/9.svg';
import { ReactComponent as PhotoFramesOval4down } from '../../assets/img/Pictures/web_avec_cadres_svg/traiteur/4down.svg';
import { ReactComponent as PhotoFramesOval7down } from '../../assets/img/Pictures/web_avec_cadres_svg/traiteur/7down.svg';
import { Helmet } from 'react-helmet-async';

export default function Caterer() {


    return (
        <>
            <Helmet>
                <title>Crousti Truck Traiteur - Montpellier</title>
                <meta name='description' content='Crousti Truck Traiteur, pour chaque occasion, cocktails, pièces apéritives, repas, desserts, pour les particuliers et les pros, un service adapté à vos besoins' />
            </Helmet>
            <Header />
            <main className="caterer background">
                {/* ATTENTION CSS/className utilisés de la page Contact */}
                <article className="contact-content">
                    <div className="caterer-content">
                        <PhotoFramesOval1 className='caterer__img caterer__img1' alt='Petis fours' />
                        <PhotoFramesOval2 className='caterer__img caterer__img2' alt='Petis fours' />
                        <PhotoFramesOval3 className='caterer__img caterer__img3' alt='Petis fours' />

                        <div className="backgroundCard backgroundCardContact-txt1 backgroundCardCaterer">
                            <h2 className="contact__p caterer-h2">Service <strong>Traiteur</strong> Crousti Truck: Une Explosion de Saveurs pour Tous Vos <strong>Événements</strong>&nbsp;!</h2>
                            <p className="contact__p contact__p2">Que ce soit pour un événement privé ou professionnel, Crousti Truck vous propose une expérience traiteur qui s’adapte à vos besoins. </p>
                            <p className="contact__p contact__p2">Nous proposons des petits déjeuners, des buffets cocktails déjeunatoires ou dinatoires, des plats Fait Maison, des prestations aussi bien salées que sucrées. </p>
                            <p className="contact__p contact__p2">Notre service traiteur s'adresse à tous. Que ce soit pour un mariage (ou lendemain de mariage), un anniversaire, une réunion d'affaires, un repas de club sportif ou tout autre événement, Crousti Truck apporte une cuisine simple et raffinée à votre porte.</p>
                            <p className="contact__p contact__p2"><a className='gradient-text' target="_blank" href="https://forms.gle/FAa8PEtj44Lzahm2A" rel="noreferrer">Contactez-nous dès maintenant pour personnaliser votre menu</a>. Nous procéderons à l’étude de toutes vos attentes et envies et nous nous adapterons à votre budget.</p>
                            <p className="contact__p contact__p2">Nous nous déplaçons sur toute la région de Montpellier et même au-delà (<strong>Occitanie</strong>, <strong>Hérault</strong>, <strong>Gard</strong>, <strong>Aude</strong> etc.).</p>
                            <p className="contact__p contact__p2">Notre prestation peut aller de la simple livraison de plateaux de pièces cocktails ou de plats à l'organisation complète de vos buffets et réceptions.</p>
                        </div>

                        <PhotoFramesOval6 className='caterer__img caterer__img6' alt='Patisseries' />
                        <PhotoFramesOval8 className='caterer__img caterer__img8' alt='Légumes' />
                        <PhotoFramesOval9 className='caterer__img caterer__img9' alt='Viénoiseries' />
                        <PhotoFramesOval4down className='caterer__img caterer__img4' alt='Gâteau' />
                        <PhotoFramesOval7down className='caterer__img caterer__img7' alt='Charcuterie' />
                    </div>

                    <div className="contact__form">
                        <Link className='btn btn-big btn-big--home btn-form' target="_blank" to="https://forms.gle/FAa8PEtj44Lzahm2A"><i className="contact__form__icon-envelope fa-regular fa-envelope" alt="email"></i>Me contacter</Link>
                        <ul className="contact__form__coordinates backgroundCard-small-grey">
                            <li className="contact__form__coordinates__p">Crousti Truck</li>
                            <li className="contact__form__coordinates__p">Mail:<a className='gradient-text' href="mailto:contact@crousti-truck.fr"> contact@crousti-truck.fr</a></li>
                            <li className="contact__form__coordinates__p">Tél: <a className='gradient-text' href="tel:+33652150605">06 52 15 06 05</a></li>
                        </ul>
                    </div>

                    <div className="contact__social backgroundCard-small" >
                        <p className="contact__social__description backgroundCardContact-txt2">Suivez-nous sur les réseaux sociaux pour rester à jour sur nos emplacements actuels, nos créations spéciales et nos événements à venir.<br className='contact-br' /> Rejoignez notre communauté gourmande en cliquant sur ces liens:
                            <span className="contact__social__btn">
                                <Link className='btn home-contact__social-btn home-contact__social-btn--facebook' target="_blank" to="https://www.facebook.com/people/Crousti-Truck/61551633789092"><i className="fa-brands fa-facebook home-contact__social-btn__svg" alt="facebook"></i></Link>
                                <Link className='btn home-contact__social-btn' target="_blank" to="https://www.instagram.com/crousti_truck"><i className="fa-brands fa-instagram home-contact__social-btn__svg" alt="instagram"></i></Link>
                            </span>
                        </p>
                    </div>

                </article>
            </main>
            <Footer />
        </>
    )
}
