import { useEffect } from 'react';

const useScrollWithOffset = (offset) => {
    const handleClick = (event) => {
        event.preventDefault(); // Empêche le comportement par défaut du lien
        const targetId = event.currentTarget.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        const targetPosition = targetElement.offsetTop;
        window.scrollTo({
            top: targetPosition - offset,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            // Votre logique de gestion du scroll ici
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Assure que cet effet ne s'exécute qu'une seule fois lors du montage

    return handleClick;
};

export default useScrollWithOffset;