import React from 'react';
import './BannerDays.css';
import { ReactComponent as Line } from '../../../assets/extra/line13.svg';
import useScrollWithOffset from '../../../hooks/useScrollWithOffset';

export default function BannerDays() {

    const handleScrollWithOffset = useScrollWithOffset(0); // Appel du hook avec l'espace supplémentaire de 100px

    return (
        <>
            <div className='bannerDays' >
                {/* <h2 className='banner__title'> Bienvenue chez Crousti Truck !</h2> */}
                <ul className="bannerDays__list">

                    <li className="bannerDays-day bannerDays-day--monday bannerDays-day--week bannerDays-day--notActive">Lundi</li>
                    {/* <a className='gradient-text' href='#socialHome'> réseaux sociaux </a> */}
                    <li className="bannerDays-day bannerDays-day--week bannerDays-day--notActive">Mardi</li>
                    {/* <Link className='gradient-text bannerDays-day--week' to='#location__map-wednesday'>Mercredi</Link> */}
                    <a className='gradient-text bannerDays-day--week' href='#location__map-wednesday' onClick={handleScrollWithOffset}>Mercredi</a>
                    <a className='gradient-text bannerDays-day--week' href='#location__map-thursday' onClick={handleScrollWithOffset}>Jeudi</a>
                    <a className='gradient-text bannerDays-day--week' href='#location__map-friday' onClick={handleScrollWithOffset}>Vendredi</a>
                    <li className="bannerDays-day bannerDays-day--week bannerDays-day--notActive">Samedi</li>
                    <li className="bannerDays-day bannerDays-day--sunday bannerDays-day--week bannerDays-day--notActive">Dimanche</li>

                </ul>
                <h3 className="bannerDays__description">Mes emplacements</h3>
                <Line className='bannerDays__line' />
            </div>
        </>
    )
}