import React from 'react';
import './Banner.css';
import { ReactComponent as Line } from '../../../assets/extra/line13.svg';

export default function Banner() {

    return (
        <>
            <div className='banner' >
                <h2 className='banner__title'> Bienvenue chez Crousti Truck !</h2>
                <h3 className="banner__description">Le FoodTruck qui vous fait Croustiller</h3>
                <Line className='banner__line' />
            </div>
        </>
    )
}