import React from 'react';
import Header from '../../Components/Layout/Header/Header';
import './Photos.css';
import Banner from '../../Components/Layout/Banner/Banner';
import Footer from '../../Components/Layout/Footer/Footer';
import Gallery from '../../Components/Layout/Gallery/Gallery';
import { Helmet } from 'react-helmet-async';

export default function Photos() {

    return (
        <>
            <Helmet>
                <meta name='description' content="Explorez notre galerie photos colorée pour un aperçu alléchant de nos Baos et de nos événements. Plongez dans l'univers délicieux de Crousti Truck." />
            </Helmet>
            <Header />
            <main className="photos background">
                <Banner />
                <Gallery />
                <div className="home-reservation__txt photos__card">
                    <div className="backgroundCard-small photos__card-background">
                        <p className='home__txt2-p home__txt2-p--descr photos__card--descr'>N'hésitez pas à partager vos propres photos de vos moments gourmands chez Crousti Truck sur les réseaux sociaux en utilisant le hashtag
                            <span className="photos__card--descr-hashtag"> <strong>#CroustiTruck</strong></span>. Vos photos pourraient figurer dans notre galerie.</p>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}