import React from 'react';
import '../Legal/Legal.css';
import { Helmet } from 'react-helmet-async';
import Header from '../../Components/Layout/Header/Header';
import Footer from '../../Components/Layout/Footer/Footer';

export default function Cookies() {
    return (
        <>
            <Helmet>
                <meta name='description' content="Politique de Cookies" />
            </Helmet>
            <Header />
            <main className="legal background">
                <section className="legal-content">
                    <div className="backgroundCard backgroundCardLegal-txt1">
                        <ul className="legal__ul"> <span className="legal__ul--title">Politique de Cookies</span>
                            <li className="legal__li legal__li--p">Dernière mise à jour : 13/01/2024</li>
                            <li className="legal__li"> Utilisation des cookies
                                <p className="legal__li--p">Notre site internet utilise des cookies et des technologies similaires pour améliorer votre expérience de navigation. En continuant à utiliser ce site, vous consentez à notre utilisation de cookies conformément à la présente politique.</p>
                            </li>
                            <li className="legal__li"> Qu'est-ce qu'un cookie ?
                                <p className="legal__li--p">Un cookie est un petit fichier texte stocké sur votre appareil lors de la visite d'un site internet. Les cookies jouent un rôle essentiel dans le fonctionnement de nombreux sites en permettant, par exemple, de mémoriser vos préférences.</p>
                            </li>
                            <li className="legal__li"> Types de cookies utilisés
                                <p className="legal__li--p"> <span className="underline"> Cookies essentiels</span> : Ce site web utilise des cookies techniques indispensables à son bon fonctionnement.</p>
                                <p className="legal__li--p"><span className="underline">Cookies de performance</span> : Ces cookies recueillent des informations sur la manière dont vous utilisez le site, par exemple, les pages que vous consultez le plus souvent. Ces données nous aident à améliorer le fonctionnement du site.</p>
                                <p className="legal__li--p"><span className="underline">Cookies de fonctionnalité</span> : Ces cookies permettent au site de mémoriser vos choix (comme votre nom d'utilisateur, votre langue ou votre région) et fournissent des fonctionnalités améliorées.</p>
                            </li>
                            <li className="legal__li">Gestion des cookies
                                <p className="legal__li--p">Vous pouvez configurer votre navigateur pour accepter ou refuser tous les cookies, ou pour vous avertir lorsqu'un cookie est installé. Cependant, la désactivation des cookies peut entraîner une expérience de navigation limitée.</p>
                            </li>
                            <li className="legal__li"> Cookies tiers
                                <p className="legal__li--p">Nous pouvons utiliser des cookies de tiers. Ces cookies sont soumis aux politiques de confidentialité de ces tiers.</p>
                            </li>
                            <li className="legal__li"> Modifications de la politique de cookies
                                <p className="legal__li--p">Nous nous réservons le droit de modifier cette politique de cookies à tout moment. Les modifications seront effectives dès leur publication sur le site.</p>
                            </li>
                            <li className="legal__li"> Contact
                                <p className="legal__li--p">Pour toute question concernant notre politique de cookies, veuillez nous contacter sur l’adresse e-mail : contact@crousti-truck.fr.</p>
                            </li>
                        </ul>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}
