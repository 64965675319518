import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from '@react-google-maps/api';
import './MapWithMarkers.css';

const libraries = ['places', 'visualization'];

const MapWithMarkers = ({ markers, markerInfo }) => {
  const [map, setMap] = useState(null);
  const [routeInfo, setRouteInfo] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  // const [showGoogleMapsButton] = useState(true); // Afficher le bouton dès le début

  // const GoogleMapsButton = ({ onClick, className }) => (
  //   <button onClick={onClick} className={className}>
  //     Voir l'itinéraire dans Google Maps
  //   </button>
  // );

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDuV6wqW408bRlJBFmBNMAR3e-7vm2Rns8', // Remplacez par votre clé API Google Maps
    libraries: libraries,
  });

  const handleLoad = (map) => {
    setMap(map);
  };

  const handleMarkerClick = (position) => () => {
    if (!map || !userPosition || !window.google || !window.google.maps) return;

    const directionsService = new window.google.maps.DirectionsService();

    const request = {
      origin: userPosition,
      destination: position,
      travelMode: window.google.maps.TravelMode.DRIVING,
      provideRouteAlternatives: true,
    };

    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        setRouteInfo(result);
        setInfoWindowOpen(true);
      } else {
        console.error('Impossible de calculer l\'itinéraire.');
      }
    });
  };

  const handleMapClick = () => {
    setRouteInfo(null);
    setInfoWindowOpen(false);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserPosition(userPos);
        },
        (error) => {
          console.error('Erreur de géolocalisation:', error);
        }
      );
    }
  }, []);

  return isLoaded ? (
    <div className='googleMap'>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={markers.length > 0 ? markers[0] : null}
        zoom={13}
        onLoad={handleLoad}
        onClick={handleMapClick}
        options={{ gestureHandling: 'greedy' }}
      >
        {markers.map((position, index) => (
          <Marker
            key={index}
            position={position}
            onClick={handleMarkerClick(position)}
          />
        ))}
        {/* markerInfo pour afficher les détails spécifiques du marqueur */}
        {infoWindowOpen && routeInfo && (
          <InfoWindow
            position={markers[0]} // Assuming info window is opened for the first marker
            onCloseClick={() => setInfoWindowOpen(false)}
          >
            <div>
              <p className='googleMap__infobulle--title'>{markerInfo.title}</p>
              <p> {markerInfo.address}<br />{markerInfo.zipCode}</p>
              {/* <p>288 Rue Helene Boucher <br />34170 Castelnau-le-Lez</p> */}
              <p>Durée : {routeInfo.routes[0].legs[0].duration.text}</p>
              <p>Distance : {routeInfo.routes[0].legs[0].distance.text}</p>
            </div>
          </InfoWindow>
        )}
        {routeInfo && (
          <DirectionsRenderer
            directions={routeInfo}
            options={{ suppressMarkers: true }}
          />
        )}
      </GoogleMap><div className="contact__social googleMap__geolocalisation backgroundCard-small" >
        <p className="contact__social__description googleMap__geolocalisation-txt backgroundCardContact-txt2">
          Note: Autorisez la géolocalisation si vous souhaitez voir directement l'itinéraire vers mon emplacement sur la carte, en cliquant sur le marqueur!
        </p></div>
      {/* {showGoogleMapsButton && (
        <GoogleMapsButton className='googleMap__button' onClick={openGoogleMaps} />
      )} */}
    </div>
  ) : (
    <></>
  );
};

export default MapWithMarkers;