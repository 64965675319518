import React from 'react';
import './Footer.css';
import FooterLogoTruck from '../../../assets/icons/logo_fait-camionx.png'
import FooterLogoOccitanie from '../../../assets/icons/logo_occitaniex.png'
import Logo from '../../../assets/icons/logo3-transparent.png';
import { Link } from 'react-router-dom';
import useScrollToTop from '../../../hooks/useScrollToTop';

export default function Footer() {

    const scrollToTop = useScrollToTop();

    return (
        <footer className="footer">
            <img src={Logo} className="footer__logoXL" alt="Crousti Truck" />
            <div className="footer-content">
                <div className="footer__logos">
                    <img src={FooterLogoTruck} className="footer__LogoTruck" alt="fait camion" />
                    <img src={FooterLogoOccitanie} className="footer__logoOccitanie" alt="fait en Occitanie" />

                    <div className="footer__logos-social">
                        <Link className='footer__logos-social--icon fa-facebook--footer' target="_blank" to="https://www.facebook.com/people/Crousti-Truck/61551633789092" aria-label="Suivez-nous sur Facebook"><i className="fa-brands fa-facebook" alt="facebook"></i></Link>
                        <Link className='footer__logos-social--icon' target="_blank" to="https://www.instagram.com/crousti_truck" aria-label="Suivez-nous sur Instagram"><i className="fa-brands fa-instagram" alt="instagram"></i></Link>
                    </div>
                </div>
                <div className="footer__contact">
                    <Link className='footer__contact-txt' to="/contact" onClick={scrollToTop}>Contact</Link>
                    <Link className='footer__contact-txt' to="/legal" onClick={scrollToTop}>Mentions légales</Link>
                    <Link className='footer__contact-txt' to="/confidentiality" onClick={scrollToTop}>Politique Générale Confidentialité</Link>
                    <Link className='footer__contact-txt' to="/cookies" onClick={scrollToTop}>Politique Générale Cookies</Link>
                    <p className="footer__contact-txt">© 2024</p>
                </div>
            </div>
        </footer>
    )
}
