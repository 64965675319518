import React from 'react';
import '../Legal/Legal.css';
import { Helmet } from 'react-helmet-async';
import Header from '../../Components/Layout/Header/Header';
import Footer from '../../Components/Layout/Footer/Footer';

export default function Confidentiality() {
    return (
        <>
            <Helmet>
                <meta name='description' content="Politique de Confidentialité" />
            </Helmet>
            <Header />
            <main className="legal background">
                <section className="legal-content">
                    <div className="backgroundCard backgroundCardLegal-txt1">
                        <ul className="legal__ul"> <span className="legal__ul--title">Politique de Confidentialité</span>
                            <li className="legal__li legal__li--p">Dernière mise à jour : 13/01/2024</li>
                            <li className="legal__li"> Collecte et Utilisation d'informations personnelles
                                <p className="legal__li--p">Formulaires de contact</p>
                                <p className="legal__li--p">Un formulaire de contact est mis à disposition dans
                                    le cadre d’une demande d’information. Vos coordonnées (e-mail, nom, consentement aux newsletters) vous sont
                                    demandées exclusivement dans le cadre de cet échange de message. Vos informations sont envoyées
                                    par mail afin que nous puissions vous recontacter en retour, et ne sont pas conservées sur nos
                                    serveurs (sauf votre e-mail si vous avez consenti à la newslettter).</p>
                            </li>
                            <li className="legal__li"> Protection des informations
                                <p className="legal__li--p">Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé, altération, divulgation ou destruction.</p>
                            </li>
                            <li className="legal__li"> Partage d'informations
                                <p className="legal__li--p">Nous ne vendons, n'échangeons ni ne transférons vos informations personnelles à des tiers sans votre consentement, sauf dans les cas prévus par la loi.</p>
                            </li>
                            <li className="legal__li">Cookies
                                <p className="legal__li--p">Nous utilisons des cookies. En continuant à utiliser notre site, vous consentez à l'utilisation de cookies.</p>
                            </li>
                            <li className="legal__li"> Droits des utilisateurs
                                <p className="legal__li--p">Vous avez le droit d'accéder, de rectifier, de supprimer ou de limiter l'utilisation de vos informations personnelles. Pour exercer ces droits, veuillez nous contacter sur l’adresse e-mail: contact@crousti-truck.fr.</p>
                            </li>
                            <li className="legal__li"> Modifications de la politique de confidentialité
                                <p className="legal__li--p">Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront effectives dès leur publication sur le site.</p>
                            </li>
                            <li className="legal__li"> Liens externes
                                <p className="legal__li--p">Ce site peut contenir des liens vers des sites externes sur lesquels l’entreprise Crousti Truck n'a aucun contrôle. Nous déclinons toute responsabilité quant au contenu de ces sites.</p>
                            </li>
                            <li className="legal__li"> Contact
                                <p className="legal__li--p">Pour toute question concernant notre politique de confidentialité, veuillez nous contacter sur l’adresse e-mail : contact@crousti-truck.fr.</p>
                            </li>
                        </ul>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}
