import React from 'react';
import Header from '../../Components/Layout/Header/Header';
import './Home.css'
import { ReactComponent as PhotoFramesOval1 } from '../../assets/img/Pictures/web_avec_cadres_svg/1xx.svg';
import { ReactComponent as PhotoFramesOval2 } from '../../assets/img/Pictures/web_avec_cadres_svg/2.svg';
import { ReactComponent as PhotoFramesOval3 } from '../../assets/img/Pictures/web_avec_cadres_svg/3.svg';
import { ReactComponent as PhotoFramesOval4 } from '../../assets/img/Pictures/web_avec_cadres_svg/4.svg';
import { ReactComponent as PhotoFramesRond5 } from '../../assets/img/Pictures/web_avec_cadres_svg/5x.svg';
import { ReactComponent as PhotoFramesRond6 } from '../../assets/img/Pictures/web_avec_cadres_svg/6x.svg';
import { Link, NavLink } from "react-router-dom";
import Footer from '../../Components/Layout/Footer/Footer';
import Banner from '../../Components/Layout/Banner/Banner';
import useScrollToTop from '../../hooks/useScrollToTop';
import useScrollWithOffset from '../../hooks/useScrollWithOffset';

export default function Home() {

  const scrollToTop = useScrollToTop();
  const handleScrollWithOffset = useScrollWithOffset(100); // Appel du hook avec l'espace supplémentaire de 100px

  return (
    <>
      <Header />
      <main className='home background' >
        <Banner />
        <article>
          <figure className="home__text1 home__text">
            <div className="home__photos">
              <PhotoFramesOval1 className='home__img home__img1' alt='Pains Bao' />
              <PhotoFramesOval2 className='home__img home__img2' alt='Pains Bao' />
            </div>
            <figcaption className="home__txt1 backgroundCard">
              <p className="home__txt1-p home__txt1-p1 home__txt1-h4"> Laissez-vous tenter par une explosion de <strong>saveurs</strong>:</p>
              <p className='home__txt1-p1'><br className='home-br' />Des <strong>baos</strong> artisanaux croustillants à la plancha pour une expérience gustative unique.
                <br className='home-br' /> C'est une véritable ode à la gourmandise que nous vous invitons à découvrir à chaque bouchée.</p>
              <p className='home__txt1-p ' >Explorez notre menu varié, découvrez nos emplacements actuels, envisagez
                <NavLink className='gradient-text' to='/privatisation' onClick={scrollToTop}> la <strong>privatisation</strong> de notre <strong>Food Truck</strong> </NavLink>
                pour vos <strong>événements</strong> spéciaux et plongez dans notre <NavLink className='gradient-text' to='/photos' onClick={scrollToTop}>galerie photos</NavLink> qui vous mettra l'eau à la bouche.</p>
              <p className='home__txt1-p ' >Chez Crousti Truck, la passion pour la <strong>cuisine</strong> se reflète dans chaque plat que nous servons.
                <br className='home-br' /> Nous sommes impatients de vous accueillir et de partager cette aventure culinaire avec vous.</p>
            </figcaption>
          </figure>
        </article>
        <article className="home__text2">
          <div className="home__photos2">
            <PhotoFramesOval3 className='home__img home__img3' alt='Remorque fermée' />
            <PhotoFramesOval4 className='home__img home__img4' alt='Remorque ouverte' />
          </div>
          <div className="home__text2Photo5">
            <div className="home__txt2 backgroundCard">
              <p className='home__txt2-p home__txt2-p1'> Notre Food Truck, fusionnant élégamment aspect bois et bleu turquoise, vous accueille dans un cadre chaleureux et convivial.</p>
              <p className='home__txt2-p' > Mais ce qui fait notre renommée, ce sont nos délicieux pains <strong>Bao</strong> artisanaux,
                garnis de <NavLink className='gradient-text' to='/menu' onClick={scrollToTop}>recettes originales</NavLink>.
                <br className='home-br' />Toutes nos garnitures salées sont <strong>Fait Maison</strong>.</p>
              <p className='home__txt2-p' >Venez découvrir une aventure culinaire unique née de la créativité d'une passionnée de la Street Food.</p>
              <p className='home__txt2-p' >
                <NavLink className='gradient-text' to='/contact' onClick={scrollToTop}> Contactez-nous </NavLink> pour plus d'informations ou suivez-nous sur les
                <a className='gradient-text' href='#socialHome' onClick={handleScrollWithOffset}> réseaux sociaux </a> pour ne rien manquer de nos prochains
                <NavLink className='gradient-text' to='/localisation' onClick={scrollToTop}> emplacements</NavLink>.</p>
            </div>
          </div>

        </article>
        <section className="home__contact">
          <div className="home-contact">
            <Link className='btn btn-big btn-big--home' to="/contact" onClick={scrollToTop}>Contact</Link>
            <div className="content-btn-big--home--2">
              <Link className='btn btn-big btn-big--home btn-big--home--2' to="/menu" onClick={scrollToTop}>Découvrez notre menu</Link>
            </div>
            <div className="home-contact__social">
              <Link id='socialHome' className='btn home-contact__social-btn home-contact__social-btn--facebook' target="_blank" to="https://www.facebook.com/people/Crousti-Truck/61551633789092" aria-label="Suivez-nous sur Facebook"><i className="fa-brands fa-facebook home-contact__social-btn__svg" alt="facebook"></i></Link>
              <Link className='btn home-contact__social-btn' target="_blank" to="https://www.instagram.com/crousti_truck" aria-label="Suivez-nous sur Instagram"><i className="fa-brands fa-instagram home-contact__social-btn__svg" alt="instagram"></i></Link>
            </div>
          </div>
          <div className="home-reservation">
            <PhotoFramesRond5 className='home__img5' alt='Pain Bao' />
            <div className="home-reservation__txt">
              <div className="backgroundCard-small">
                <p className='home__txt2-p home__txt2-p--descr'> Vous pouvez <strong>réserver votre repas</strong> en avance et venir le récupérer au Food Truck lors de votre pause déjeuner !</p>
              </div>
              <Link className='btn btn-big btn-home--reservation' to="/contact" onClick={scrollToTop}>Réserver votre repas</Link>
            </div>
            <PhotoFramesRond6 className='home__img6' alt='Pains Bao' />
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
