import React from 'react';
import Header from '../../Components/Layout/Header/Header';
import './NotFound.css';
import Banner from '../../Components/Layout/Banner/Banner';
import Footer from '../../Components/Layout/Footer/Footer';
import { Link } from "react-router-dom";
import useScrollToTop from '../../hooks/useScrollToTop';

/** Create page 404 */
export default function NotFound() {

    const scrollToTop = useScrollToTop();

    return (
        <>
            <Header />
            <Banner />
            <main className="notFound background">
                <h1 className="notFound__title">
                    404
                </h1>
                <h2 className="notFound__txt">
                    Oups! La page que vous demandez n'existe pas.
                </h2>

                <Link className='notFound__link gradient-text' to='/' onClick={scrollToTop}>Retourner à l'accueil</Link>
            </main>

            <Footer />
        </>
    )
}