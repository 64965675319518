import React from 'react';
import './Legal.css';
import { Helmet } from 'react-helmet-async';
import Header from '../../Components/Layout/Header/Header';
import Footer from '../../Components/Layout/Footer/Footer';

export default function Legal() {
    return (
        <>
            <Helmet>
                <meta name='description' content="Mentions légales" />
            </Helmet>
            <Header />
            <main className="legal background">
                <section className="legal-content">
                    <div className="backgroundCard backgroundCardLegal-txt1">
                        <ul className="legal__ul"> <span className="legal__ul--title">Mentions légales</span>
                            <li className="legal__li"> Éditeur du site
                                <p className="legal__li--p">CROUSTI TRUCK</p>
                                <p className="legal__li--p">34970 LATTES</p>
                                <p className="legal__li--p">06 52 15 06 05 </p>
                                <p className="legal__li--p">contact@crousti-truck.fr</p>
                                <p className="legal__li--p">RCS 884 983 198</p>
                            </li>
                            <li className="legal__li"> Directeur de la publication
                                <p className="legal__li--p">Mme Tiffany MICHEL</p>
                            </li>
                            <li className="legal__li"> Hébergeur du site
                                <p className="legal__li--p">SAS OVH - https://www.ovh.com/ </p>
                                <p className="legal__li--p">2 rue Kellermann</p>
                                <p className="legal__li--p">BP 80157</p>
                                <p className="legal__li--p">59100 Roubaix</p>
                                <p className="legal__li--p">support@ovh.com</p>
                            </li>
                            <li className="legal__li"> Propriété intellectuelle
                                <p className="legal__li--p">Le contenu de ce site (textes, images, vidéos, etc.) est la propriété de l’entreprise Crousti Truck ou de ses partenaires. Toute reproduction totale ou partielle est interdite sans autorisation préalable.</p>
                            </li>
                            <li className="legal__li">Données personnelles
                                <p className="legal__li--p">Les informations collectées via ce site sont utilisées uniquement à des fins de statistiques. Conformément à la loi, vous disposez d'un droit d'accès, de rectification et de suppression de vos données.</p>
                            </li>
                            <li className="legal__li"> Cookies
                                <p className="legal__li--p">Ce site utilise des cookies. En continuant à naviguer sur le site, vous acceptez l'utilisation de ces cookies.</p>
                            </li>
                            <li className="legal__li"> Responsabilité
                                <p className="legal__li--p">L’entreprise Crousti Truck ne peut être tenue responsable des erreurs ou omissions présentes sur le site. Les informations sont fournies à titre indicatif et peuvent être modifiées à tout moment.</p>
                            </li>
                            <li className="legal__li"> Liens externes
                                <p className="legal__li--p">Ce site peut contenir des liens vers des sites externes sur lesquels l’entreprise Crousti Truck n'a aucun contrôle. Nous déclinons toute responsabilité quant au contenu de ces sites.</p>
                            </li>
                            <li className="legal__li"> Contact
                                <p className="legal__li--p">Pour toute question ou réclamation, vous pouvez nous contacter par e-mail contact@crousti-truck.fr.</p>
                            </li>
                        </ul>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}
