import Home from './pages/Home/Home';
import { Route, Routes } from 'react-router-dom';
import Menu from './pages/Menu/Menu';
import Contact from './pages/Contact/Contact';
import Location from './pages/Location/Location';
import Photos from './pages/Photos/Photos';
import Private from './pages/Private/Private';
import { HelmetProvider } from 'react-helmet-async';
import NotFound from './pages/NotFound/NotFound';
import Legal from './pages/Legal/Legal';
import Confidentiality from './pages/Confidentiality/Confidentiality';
import Cookies from './pages/Cookies/Cookies';
import Caterer from './pages/Caterer/Caterer';

function App() {
  return (

    <HelmetProvider>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/localisation' element={<Location />} />
          <Route path='/photos' element={<Photos />} />
          <Route path='/privatisation' element={<Private />} />
          <Route path='/legal' element={<Legal />} />
          <Route path='/confidentiality' element={<Confidentiality />} />
          <Route path='/cookies' element={<Cookies />} />
          <Route path='/caterer' element={<Caterer />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <h1 className="referencementApp">Crousti Truck</h1> {/* pour le référencement */}
      </div>
    </HelmetProvider>

  );
}

export default App;
