import React from 'react';
import Header from '../../Components/Layout/Header/Header';
import './Location.css';
import { Link } from "react-router-dom";
// import Banner from '../../Components/Layout/Banner/Banner';
import Footer from '../../Components/Layout/Footer/Footer';
// import Construction from '../../Components/Layout/Construction/Construction';
import { Helmet } from 'react-helmet-async';
import MapWithMarkers from '../../Components/Layout/MapWithMarkers/MapWithMarkers';
import useScrollToTop from '../../hooks/useScrollToTop';
import BannerDays from '../../Components/Layout/BannerDays/BannerDays';


export default function Location() {

    const castelnauPosition = {
        lat: 43.6234118827078,
        lng: 3.912117495975315,
    }; // Coordonnées de 288 Rue Helene Boucher, 34170 Castelnau-le-Lez

    const frejorguesnauPosition = {
        lat: 43.58833829184178,
        lng: 3.9417468570684875,
    };

    const saintJeanPosition = {
        lat: 43.582617950184535,
        lng: 3.845224726383417,
    };

    const scrollToTop = useScrollToTop();

    // Informations spécifiques du marqueur (utilisées pour l'infobulle)
    const markerInfoWednesday = {
        title: 'Crousti Truck',
        address: '144 rue du Rajol Z.A. Fréjorgues Est',
        zipCode: '34130 Mauguio',
    };
    const markerInfoThursday = {
        title: 'Crousti Truck',
        address: '288 Rue Helene Boucher',
        zipCode: '34170 Castelnau-le-Lez',
    };
    const markerInfoFriday = {
        title: 'Crousti Truck',
        address: '60 Rue Pierre et Marie Curie',
        zipCode: '34430 Saint-Jean-de-Védas',
    };

    return (
        <>
            <Helmet>
                <meta name='description' content='Trouvez notre food truck facilement grâce à nos emplacements régulièrement mis à jour. Suivez-nous pour ne rien manquer de nos délicieux Baos.' />
            </Helmet>
            <Header />
            <main className="location background">
                {/* <Banner /> */}
                <BannerDays />
                {/* <Construction /> */}
                <div className="location__map" id='location__map-wednesday'>
                    {/* <MapWithMarkers /> */}
                    <MapWithMarkers markers={[frejorguesnauPosition]} markerInfo={markerInfoWednesday} />

                    {/* <iframe title='mapTest' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d180.51190314518342!2d3.912013439682199!3d43.623394885557666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b6a58c047c9223%3A0xc5357135a464b317!2s288%20Rue%20Helene%20Boucher%2C%2034170%20Castelnau-le-Lez!5e0!3m2!1sfr!2sfr!4v1704155696756!5m2!1sfr!2sfr" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}


                    <div className="home__text2Photo5 location__map-content">
                        <div className="home__txt2 backgroundCard backgroundCard-location">
                            <h4 className="home__txt2-p home__txt2-p1 location__map-content-emplacements">Locavorium Fréjorgues <strong className="location__map-content-emplacements-strong">Mauguio</strong></h4>
                            <div className='home__txt2-p location__map-content-p'>
                                <span className="location__map-content-jeudis">mercredi midi</span> <span> de </span>
                                <span className="location__map-content-horaires">11h30 à 14h</span> au:
                                <p className='location__map-content-p'>
                                    Locavorium Fréjorgues <br />
                                    <Link className='gradient-text' target="_blank" to='https://maps.app.goo.gl/2kWBfdLA9AyQHPhu8' onClick={scrollToTop}>
                                        144 rue du Rajol Z.A. Fréjorgues Est <br /> 34130 Mauguio</Link><br />
                                    <span className="location__map-content-coordonnees"> (Coordonnées gps: 43.58834, 3.94168)</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="location__map" id='location__map-thursday'>
                    <MapWithMarkers markers={[castelnauPosition]} markerInfo={markerInfoThursday} />

                    <div className="home__text2Photo5 location__map-content">
                        <div className="home__txt2 backgroundCard backgroundCard-location">
                            <h4 className="home__txt2-p home__txt2-p1 location__map-content-emplacements">Maison de l'Hospitalisation Privée <strong>Castelnau-le-Lez</strong></h4>
                            <div className='home__txt2-p location__map-content-p'>
                                <span className="location__map-content-jeudis">jeudi midi </span> <span>de </span>
                                <span className="location__map-content-horaires">11h30 à 14h</span> au:
                                <p className='location__map-content-p'>
                                    <Link className='gradient-text' target="_blank" to='https://maps.app.goo.gl/459is56axko9yKv57' onClick={scrollToTop}>
                                        288 Rue Helene Boucher <br /> 34170 Castelnau-le-Lez</Link><br />
                                    <span className="location__map-content-coordonnees"> (Coordonnées gps: 43.63318, 3.91391)</span></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="location__map" id='location__map-friday'>
                    <MapWithMarkers markers={[saintJeanPosition]} markerInfo={markerInfoFriday} />

                    <div className="home__text2Photo5 location__map-content">
                        <div className="home__txt2 backgroundCard backgroundCard-location">
                            <h4 className="home__txt2-p home__txt2-p1 location__map-content-emplacements">Locavorium <strong>Saint-Jean</strong></h4>
                            <div className='home__txt2-p location__map-content-p'>
                                <span className="location__map-content-jeudis">vendredi midi </span> <span>de </span>
                                <span className="location__map-content-horaires">11h30 à 14h</span> au:
                                <p className='location__map-content-p'>
                                    Locavorium Saint-Jean<br />
                                    <Link className='gradient-text' target="_blank" to='https://maps.app.goo.gl/459is56axko9yKv57' onClick={scrollToTop}>
                                        60 Rue Pierre et Marie Curie <br /> 34430 Saint-Jean-de-Védas</Link><br />
                                    <span className="location__map-content-coordonnees"> (Coordonnées gps: 43.58258, 3.84521)</span></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="contact__form location__contact">
                    <Link className='btn btn-big btn-big--home btn-form' target="_blank" to="https://forms.gle/FAa8PEtj44Lzahm2A"><i className="contact__form__icon-envelope fa-regular fa-envelope" alt="email"></i>Me contacter</Link>
                    <ul className="contact__form__coordinates backgroundCard-small-grey">
                        <li className="contact__form__coordinates__p">Crousti Truck</li>
                        <li className="contact__form__coordinates__p">Mail:<a className='gradient-text' href="mailto:contact@crousti-truck.fr"> contact@crousti-truck.fr</a></li>
                        <li className="contact__form__coordinates__p">Tél: <a className='gradient-text' href="tel:+33652150605">06 52 15 06 05</a></li>
                    </ul>
                </div>

                <div className="contact__social location__social backgroundCard-small" >
                    <p className="contact__social__description backgroundCardContact-txt2">Suivez-nous sur les réseaux sociaux pour rester à jour sur nos emplacements actuels, nos créations spéciales et nos événements à venir.<br className='contact-br' /> Rejoignez notre communauté gourmande en cliquant sur ces liens:
                        <span className="contact__social__btn">
                            <Link className='btn home-contact__social-btn home-contact__social-btn--facebook' target="_blank" to="https://www.facebook.com/people/Crousti-Truck/61551633789092" aria-label="Suivez-nous sur Facebook"><i className="fa-brands fa-facebook home-contact__social-btn__svg" alt="facebook"></i></Link>
                            <Link className='btn home-contact__social-btn' target="_blank" to="https://www.instagram.com/crousti_truck" aria-label="Suivez-nous sur Instagram"><i className="fa-brands fa-instagram home-contact__social-btn__svg" alt="instagram"></i></Link>
                        </span>
                    </p>
                </div>
            </main>

            <Footer />
        </>
    )
}