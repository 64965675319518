import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from '../../../assets/icons/logo3-transparentxx.png';
import { Link, NavLink } from "react-router-dom";
import useScrollToTop from '../../../hooks/useScrollToTop';
import HamburgerMenu from 'react-hamburger-menu';

export default function Header() {

  // retour en haut à un changement de page
  const scrollToTop = useScrollToTop();

  // changements NavLink si écran < 490px
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 860);

  const handleResize = () => {
    setIsWideScreen(window.innerWidth <= 860);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //menu hamburger
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  //vérifie que l'événement ne provient pas du bouton du menu hamburger avant de fermer le menu
  const closeMenu = (event) => {
    const menu = document.querySelector('.mobile-menu');
    const hamburgerButton = document.querySelector('.hamburger-menu');
    
    if (
      menu &&
      !menu.contains(event.target) &&
      !hamburgerButton.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    // Ajoutez un gestionnaire d'événements au document pour détecter les clics en dehors du menu
    document.addEventListener('click', closeMenu);
  
    // Nettoyez le gestionnaire d'événements lors du démontage du composant
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [isMenuOpen]);

  return (
    <header className='header'>

      <nav className='nav'>
        <NavLink to='/' onClick={scrollToTop}
          className={({ isActive }) => isActive ? 'activeLink nav-home nav-desktop' : 'notAvtiveLink nav-home nav-desktop'}
        >
          Accueil
        </NavLink>

        <NavLink to='/menu' onClick={scrollToTop}
          className={({ isActive }) => isActive ? 'activeLink nav-menu nav-desktop' : 'notAvtiveLink nav-menu nav-desktop'}
        >
          Menu
        </NavLink>

        <NavLink to='/localisation' onClick={scrollToTop}
          className={({ isActive }) => isActive ? 'activeLink nav-localisation nav-desktop' : 'notAvtiveLink nav-localisation nav-desktop'}
        >
          Localisation
        </NavLink>

        <div className="nav-order-content nav-desktop">
          <NavLink to='/order' onClick={scrollToTop}
            className={({ isActive }) => isActive ? 'activeLink nav-order' : 'notAvtiveLink nav-order nav-desktop'}
          >
            Commander
          </NavLink>
          <span className="nav-order--soon notAvtiveLink">bientôt</span>
        </div>

        <Link className='header__logo' onClick={scrollToTop} to="/" aria-label="Retourner à l'accueil"><h1 className='header__logo--h1'><img src={Logo} className="header__logo--img" alt="Crousti Truck" /></h1></Link>

        <div className="nav-caterer-content nav-desktop">
          <NavLink to='/caterer' onClick={scrollToTop}
            className={({ isActive }) => isActive ? 'activeLink nav-caterer' : 'notAvtiveLink nav-caterer nav-desktop'}
          >
            Traiteur
          </NavLink>
          <span className="nav-caterer--new notAvtiveLink">nouveau</span>
        </div>

        <NavLink to='/privatisation' onClick={scrollToTop}
          className={({ isActive }) => isActive ? 'activeLink nav-private nav-desktop' : 'notAvtiveLink nav-private nav-desktop'}
        >
          Privatisation
        </NavLink>

        <NavLink to='/photos' onClick={scrollToTop}
          className={({ isActive }) => isActive ? 'activeLink nav-photos nav-desktop' : 'notAvtiveLink nav-photos nav-desktop'}
        >
          {/* Galerie Photos */}
          {isWideScreen ? 'Photos' : 'Galerie Photos'}
        </NavLink>

        <NavLink to='/contact' onClick={scrollToTop}
          className={({ isActive }) => isActive ? 'activeLink nav-contact nav-desktop' : 'notAvtiveLink nav-contact nav-desktop'}
        >
          Contact
        </NavLink >

        <div className="header__social">
          <Link className='header__social--icon fa-facebook--header' target="_blank" to="https://www.facebook.com/people/Crousti-Truck/61551633789092" aria-label="Suivez-nous sur Facebook"><i className="fa-brands fa-facebook" alt="facebook"></i></Link>
          <Link className='header__social--icon' target="_blank" to="https://www.instagram.com/crousti_truck" aria-label="Suivez-nous sur Instagram"><i className="fa-brands fa-instagram" alt="instagram"></i></Link>
        </div>

        <HamburgerMenu className='hamburger-menu'
          isOpen={isMenuOpen}
          menuClicked={() => setIsMenuOpen(!isMenuOpen)}
          width={40}
          height={34}
          strokeWidth={5}
          rotate={0}
          // color='#fce100'
          color='#b3adab'
          borderRadius={0}
          animationDuration={0.5}
        />
        {isMenuOpen  ? (
          <>
            <div className="mobile-menu-overlay" onClick={closeMenu}></div>
            {/* <div ref={node}> */}
            <div className="mobile-menu">
              {/* Les éléments du menu pour mobile */}
              <NavLink to='/' onClick={scrollToTop} className='nav-home' >Accueil </NavLink>
              <NavLink to='/menu' onClick={scrollToTop} className='nav-menu'>Menu</NavLink>
              <div className="nav-order-content">
                <NavLink to='/order' onClick={scrollToTop} className="nav-order" > Commander</NavLink>
                <span className="nav-order--soon notAvtiveLink">bientôt</span>
              </div>
              <NavLink to='/localisation' onClick={scrollToTop} className='nav-localisation'>Localisation</NavLink>
              <NavLink to='/privatisation' onClick={scrollToTop} className='nav-private' >Privatisation</NavLink>
              <div className="nav-caterer-content">
                <NavLink to='/caterer' onClick={scrollToTop} className='nav-caterer'>Traiteur</NavLink>
                <span className="nav-caterer--new notAvtiveLink">nouveau</span>
              </div>
              <NavLink to='/photos' onClick={scrollToTop} className='nav-photos'>
                {isWideScreen ? 'Photos' : 'Galerie Photos'}
              </NavLink>
              <NavLink to='/contact' onClick={scrollToTop} className='nav-contact'>Contact</NavLink >
            </div>
          </>
        ) : null}
        {/* Autres éléments de barre de navigation */}
      </nav>

    </header>
  )
}
