import React from 'react';
import Header from '../../Components/Layout/Header/Header';
import './Private.css';
import Footer from '../../Components/Layout/Footer/Footer';
import { Link } from 'react-router-dom';
import { ReactComponent as PhotoFramesOval9 } from '../../assets/img/Pictures/web_avec_cadres_svg/9.svg';
import { ReactComponent as PhotoFramesOval10 } from '../../assets/img/Pictures/web_avec_cadres_svg/10xx.svg';
import { ReactComponent as PhotoFramesOval11 } from '../../assets/img/Pictures/web_avec_cadres_svg/11xx.svg';
import { ReactComponent as PhotoFramesOval12 } from '../../assets/img/Pictures/web_avec_cadres_svg/12x.svg';
import { Helmet } from 'react-helmet-async';

export default function Private() {


    return (
        <>
            <Helmet>
                <title>Crousti Truck Privatisation - Montpellier</title>
                <meta name='description' content='Rendez votre événement mémorable avec Crousti Truck. Privatisez notre food truck pour une expérience culinaire exceptionnelle à votre convenance.' />
            </Helmet>
            <Header />
            <main className="contact background">
                {/* ATTENTION CSS/className utilisés de la page Contact */}
                <article className="contact-content">
                    <div className="private__photos private__photos1">
                        <PhotoFramesOval9 className='private__img private__img9' alt='Pains Bao' />
                        <PhotoFramesOval10 className='private__img private__img10' alt='Pains Bao' />
                    </div>
                    <div className="backgroundCard backgroundCardContact-txt1">
                        <h2 className="contact__p private-h2">Privatisez Crousti Truck pour Vos Événements&nbsp;!</h2>
                        <p className="contact__p contact__p2">Chez Crousti Truck, nous croyons que chaque événement spécial mérite une expérience culinaire exceptionnelle.
                            <br className='contact-br' /> C'est pourquoi nous vous offrons la possibilité de privatiser notre Food Truck pour créer des souvenirs inoubliables.</p>

                        <p className="contact__p contact__p2">Que ce soit pour un <strong>mariage</strong>, un <strong>anniversaire</strong>, une <strong>fête d'entreprise</strong>, une <strong>soirée</strong> ou tout autre événement, notre Food Truck apportera une touche unique à votre réception.</p>
                        <p className="contact__p contact__p2">Imaginez-vous et vos invités dégustant nos délicieux Baos croustillants, préparés avec créativité et passion, dans le cadre de votre choix.</p>
                        <p className="contact__p contact__p2">Nous travaillerons en étroite collaboration avec vous pour personnaliser le menu en fonction de vos préférences et des besoins de votre événement.
                            <br className='contact-br' />Que vous souhaitiez une sélection de nos Baos les plus populaires ou une création spéciale pour l'occasion, nous sommes là pour satisfaire vos papilles.
                            <br className='contact-br' />Notre équipe dévouée s'occupera de tous les détails, de la préparation des plats au service de vos invités.
                            <br className='contact-br' />Vous pourrez ainsi vous détendre et profiter de votre événement en toute tranquillité.
                            Contactez-nous dès aujourd'hui pour discuter de la privatisation de Crousti Truck pour votre prochain événement.
                        </p>
                        <p className="contact__p contact__p2">Offrez à vos invités une expérience culinaire qu'ils ne sont pas prêts d'oublier.</p>
                    </div>
                    <div className="private__photos private__photos2">
                        <PhotoFramesOval11 className='private__img private__img11' alt='Pains Bao' />
                        <PhotoFramesOval12 className='private__img private__img12' alt='Pains Bao' />
                    </div>
                    <div className="backgroundCard backgroundCardContact-txt1 private__secondCard">
                        <p className="contact__p">Nous nous déplaçons sur toute la région de Montpellier et même au-delà (<strong>Occitanie</strong>, <strong>Hérault</strong>, <strong>Gard</strong>, <strong>Aude</strong> etc.).</p>
                        <p className="contact__p contact__p2">Nous proposons aussi des <strong>petits déjeuners</strong>, des <strong>buffets</strong> de <strong>cocktails</strong> déjeunatoires ou dînatoires, des plats Fait Maison, des prestations aussi bien salées que sucrées.</p>
                        <p className="contact__p contact__p2">Notre prestation peut aller de la simple <strong>livraison</strong> de plateaux de <strong>pièces cocktails</strong> ou de <strong>plats</strong> à l'organisation complète de vos buffets et réceptions.</p>
                        <p className="contact__p contact__p2"><a className='gradient-text' target="_blank" href="https://forms.gle/FAa8PEtj44Lzahm2A" rel="noreferrer">CONTACTEZ NOUS</a> POUR L’ÉTUDE DE TOUTES VOS ATTENTES ET ENVIES, NOUS NOUS ADAPTERONS À VOS BESOINS ET À VOTRE BUDGET.</p>

                    </div>

                    <div className="contact__form">
                        <Link className='btn btn-big btn-big--home btn-form' target="_blank" to="https://forms.gle/FAa8PEtj44Lzahm2A"><i className="contact__form__icon-envelope fa-regular fa-envelope" alt="email"></i>Me contacter</Link>
                        <ul className="contact__form__coordinates backgroundCard-small-grey">
                            <li className="contact__form__coordinates__p">Crousti Truck</li>
                            <li className="contact__form__coordinates__p">Mail:<a className='gradient-text' href="mailto:contact@crousti-truck.fr"> contact@crousti-truck.fr</a></li>
                            <li className="contact__form__coordinates__p">Tél: <a className='gradient-text' href="tel:+33652150605">06 52 15 06 05</a></li>
                        </ul>
                    </div>

                    <div className="contact__social backgroundCard-small" >
                        <p className="contact__social__description backgroundCardContact-txt2">Suivez-nous sur les réseaux sociaux pour rester à jour sur nos emplacements actuels, nos créations spéciales et nos événements à venir.<br className='contact-br' /> Rejoignez notre communauté gourmande en cliquant sur ces liens:
                            <span className="contact__social__btn">
                                <Link className='btn home-contact__social-btn home-contact__social-btn--facebook' target="_blank" to="https://www.facebook.com/people/Crousti-Truck/61551633789092"><i className="fa-brands fa-facebook home-contact__social-btn__svg" alt="facebook"></i></Link>
                                <Link className='btn home-contact__social-btn' target="_blank" to="https://www.instagram.com/crousti_truck"><i className="fa-brands fa-instagram home-contact__social-btn__svg" alt="instagram"></i></Link>
                            </span>
                        </p>

                    </div>
                </article>
            </main>
            <Footer />
        </>
    )
}
