import React, { useState, useEffect } from 'react';
import './Gallery.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';

// Import des images
import image1Small from '../../../assets/img/Pictures/web/small/1small.jpg';
import image2Small from '../../../assets/img/Pictures/web/small/2small.jpg';
import image3Small from '../../../assets/img/Pictures/web/small/3small.jpg';
import image4Small from '../../../assets/img/Pictures/web/small/4small.jpg';
import image5Small from '../../../assets/img/Pictures/web/small/5small.jpg';
import image5bisSmall from '../../../assets/img/Pictures/web/small/5bis-small.jpg';
import image6Small from '../../../assets/img/Pictures/web/small/6small.jpg';
import image6bisSmall from '../../../assets/img/Pictures/web/small/6bis-small.jpg';
import image7Small from '../../../assets/img/Pictures/web/small/7small.jpg';
import image8Small from '../../../assets/img/Pictures/web/small/8small.jpg';
import image9Small from '../../../assets/img/Pictures/web/small/9small.jpg';
import image10Small from '../../../assets/img/Pictures/web/small/10small.jpg';
import image11Small from '../../../assets/img/Pictures/web/small/11small.jpg';
import image12Small from '../../../assets/img/Pictures/web/small/12small.jpg';

export default function Gallery() {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 910);

    const images = [
        image1Small, image2Small, image3Small, image4Small, image5Small, image5bisSmall, image6Small,
        image6bisSmall, image7Small, image8Small, image9Small, image10Small, image11Small, image12Small
    ];

    console.log(currentImageIndex);

    const openModal = (image, index) => {
        setSelectedImage(image);
        setCurrentImageIndex(index);
        setOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setOpen(false);
    };

    const handlePrev = () => {
        const newIndex = (currentImageIndex - 1 + images.length) % images.length;

        if (!isNaN(newIndex)) {
            setSelectedImage(images[newIndex]);
            setCurrentImageIndex(newIndex);
        }
    };

    const handleNext = () => {
        const newIndex = (currentImageIndex + 1) % images.length;

        if (!isNaN(newIndex)) {
            setSelectedImage(images[newIndex]);
            setCurrentImageIndex(newIndex);
        }
    };

    const closeIcon = (
        <FontAwesomeIcon icon={faXmark} className='gallery__modal--close' />
    );

    const modalStyles = {
        overlay: {
            background: "#f7f0e4"
        },
        modal: {
            margin: "0",
            padding: "0",
            background: "none",
            boxShadow: "unset",
            maxWidth: "unset",
            width: "90%",
            height: "100vh",
        }
    };

    const handleResize = () => {
        setIsWideScreen(window.innerWidth <= 910);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isWideScreen) {
        modalStyles.modal.width = "98%";
    }

    return (
        <div className="gallery unselectable">
            {images.map((image, index) => (
                <div key={index} className="gallery__thumbnail">
                    <img
                        src={image}
                        alt={`Gallerie ${index}`}
                        onClick={() => openModal(image, index)}
                        className={`gallery__thumbnail-content gallery__thumbnail-content${index}`}
                    />
                </div>
            ))}
            <Modal open={open} onClose={closeModal} closeIcon={closeIcon} center styles={modalStyles}>
                {selectedImage && (
                    <div className="modal-content gallery__modal unselectable">
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="gallery__modal--chevronLeft"
                            onClick={handlePrev}
                        />
                        <img src={selectedImage} alt="Enlarged" className="enlarged-photo gallery__modal--enlarged-photo" />
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className="gallery__modal--chevronRight"
                            onClick={handleNext}
                        />
                    </div>
                )}
            </Modal>
        </div>
    );
}